<template>
    <div class="document_main_wrapper">
        <b-card>
        <!-- SEARCH INPUT -->
		<b-row>
			<b-col>
				<b-form-group label="Status">
					<b-dropdown
						id="status-dropdown-1"
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						:text="studentData.visaStatus ? studentData.visaStatus.status : 'pending'"
						v-if="$route.meta.action=='write' || $route.meta.action==undefined"
						:variant="studentData.visaStatus ? studentData.visaStatus.variant : 'warning'"
					>
						<b-dropdown-item v-if="studentData.visaStatus && studentData.visaStatus.status!=='Pending'" variant="warning" @click.prevent="visaDate='',changeStatus({status:'Pending',variant:'warning'})">Pending</b-dropdown-item>
						<b-dropdown-item v-if="(studentData.visaStatus && studentData.visaStatus.status!=='Applied')||!studentData.visaStatus" variant="primary" @click.prevent="visaDate='',changeStatus({status:'Applied',variant:'primary'})">Applied</b-dropdown-item>
						<b-dropdown-item v-if="(studentData.visaStatus && studentData.visaStatus.status!=='Booked')||!studentData.visaStatus" variant="info" @click.prevent="$bvModal.show('choose-date-modal')">Booked</b-dropdown-item>
					</b-dropdown>
					<b-badge
						pill
						v-else
						:variant="'light-'+studentData.visaStatus ? studentData.visaStatus.variant : 'warning'"
					>
						{{studentData.visaStatus ? studentData.visaStatus.status : 'pending'}}
					</b-badge>
				</b-form-group>
			</b-col>
			<b-col>
				<b-form-group label="Date">
					<span>{{getVisaDate}}</span>
				</b-form-group>
			</b-col>
			<b-col v-if="$route.meta.action=='write' || $route.meta.action==undefined">
				<b-button
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					variant="outline-primary"
					@click="selectedDocName='',modalShow = !modalShow"
					v-model="modalShow"
					style="float:right"
					ref='openCreateDocModal'
				>
					Add Document
				</b-button>
			</b-col>
		</b-row>
        <!-- Visa Application Details -->
        <b-card
            v-if="selctedCourseObject && Object.keys(selctedCourseObject).length > 0"
            header="Admission Offered Details"
            header-bg-variant="#f4f4f4"
            style="background-color:#f2f2f2;margin-top: 10px;"
        >
            <b-row  class="card-title-border-bottom">
                <b-col md="2">
                    <b-form-group label="Application Number">
                        <a class="addLinkToAnchor" @click.prevent="handleRowClick()">{{ selctedCourseObject.applicationNo }}</a>
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="College Name">
                        {{ selctedCourseObject.collegeName }}
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="University Name">
                        {{ selctedCourseObject.universityName }}
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Field Name">
                        {{ selctedCourseObject.fieldName }}
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Course Name">
                        {{ selctedCourseObject.courseName }}
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-form-group label="Application Fees">
                        {{ selctedCourseObject.applicationFees }}
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
			<b-col
			md="6"
			sm="6"
			class="d-flex align-items-center justify-content-start "
			>
				<!-- <b-form-group    
					class="mb-2"
				>     
					<b-form-input
						id="filterInput"
						v-model="filter"
						type="search"
						placeholder="Type to Search"
					/>
				</b-form-group> -->
			</b-col>
         <!-- SEARCH END -->
         <!-- PAGINATION START -->      
			<b-col
			md="6"
			class="d-flex justify-content-end mb-1 mb-md-0"       
			>
				<!-- button -->
                <v-select
                    v-model="perPage"
                    style="width: 90px"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
			</b-col>
        </b-row>
        <!-- PAGINATION END -->
        <!-- LIST OF DOCS -->
        <b-table
        striped
        hover
        responsive
        show-empty
        empty-text="No matching records found"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="columns"
        :filter="filter"
        v-model="companyDocTable"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        class="documentable_management_wrapper position-relative p-0_table"
        >
        <template #cell(no)="data">  
           <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
        </template>  
        <template #cell(upload_demo_file)="data">  
            <b-col class="" v-if="data.item.collectionFile.length">
				{{getNameWithoutId(data.item.collectionFile[0].imageName)}}
            </b-col>    
            <b-col v-else>-</b-col>
            <!-- <b-col>{{Number(data.index + 1)}}</b-col>-->
        </template>  
        <template #cell(createdAt)="data">  
            <b-col class="">
				{{getDocDate(data.item.createdAt)}}
            </b-col>
        </template>
        <template #cell(action)="data">
            <div style="display: flex;justify-content: end;">
				<b-img  style='cursor:pointer;pointer-events: auto;' v-show="data.item.collectionFile.length > 0" src='@/assets/images/erflog/Download.png' @click="downloadAttachments(data)" :aria-disabled="isLoading" class="action_icon"/>
				<div v-if="$route.meta.action=='write' || $route.meta.action==undefined">
					<b-img src='@/assets/images/erflog/Edit.png' :aria-disabled="isLoading" @click="editDoc(data)" class="action_icon"/>
					<b-img src='@/assets/images/erflog/Delete.png' :aria-disabled="isLoading" @click.prevent="selectedRemoveData=data" v-b-modal.delete-field class="action_icon"/>
				</div>
            </div>
        </template>
        </b-table>

         <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                    </b-col>    
                    <!-- PAGINATION FOOTER -->
                    <b-col
                        cols="12"
                        md="12"
                        class="d-flex align-items-center justify-content-end"
                    >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalPagination"
                        :per-page="perPage"
                        right
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                    </b-pagination>
                    </b-col>
                </b-row>
        </div>
        <p v-show="items.length == 0" style="color: red;font-size: small;font-style: italic;text-align: center;">No Data Found</p>      
        </b-card>

        <!-- Visa Application Details -->
        <b-card v-if="visaApplicationDetails && Object.keys(visaApplicationDetails).length > 0">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="onlyRead == false"
              style="margin-bottom: 20px;margin-left: auto;display: table;font-size: 17px;font-weight: 600;"
              class="btn-icon"
              @click.prevent="changeRoute"
            >
              Edit Visa Application
            </b-button>
            <!-- Fathers Details -->
            <b-card
                v-if="visaApplicationDetails && Object.keys(visaApplicationDetails).length > 0"
                header="Father’s Details"
                header-bg-variant="#f4f4f4"
                style="background-color:#f2f2f2"
            >
                <b-row  class="card-title-border-bottom">
                    <b-col md="3">
                        <b-form-group label="First Name">
                            {{ visaApplicationDetails.fatherFirstName }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Last Name">
                            {{ visaApplicationDetails.fatherLastName }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Date Of Birth">
                            {{ visaApplicationDetails.fatherDob }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Place Of Birth">
                            {{ visaApplicationDetails.fatherPlaceOfBirth }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Nationality">
                            {{ visaApplicationDetails.fatherNationality }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Place of Residence (City & State)">
                            {{ visaApplicationDetails.fatherPlaceofResidence }}
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
            <!-- Mothers Details -->
            <b-card
                v-if="visaApplicationDetails && Object.keys(visaApplicationDetails).length > 0"
                header="Mother’s Details"
                header-bg-variant="#f4f4f4"
                style="background-color:#f2f2f2"
            >
                <b-row  class="card-title-border-bottom">
                    <b-col md="3">
                        <b-form-group label="First Name">
                            {{ visaApplicationDetails.motherFirstName }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Last Name">
                            {{ visaApplicationDetails.motherLastName }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Date Of Birth">
                            {{ visaApplicationDetails.motherDob }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Place Of Birth">
                            {{ visaApplicationDetails.motherPlaceOfBirth }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Nationality">
                            {{ visaApplicationDetails.motherNationality }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Place of Residence (City & State)">
                            {{ visaApplicationDetails.motherPlaceofResidence }}
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
            <!-- Sponsor Details -->
            <b-card
                v-if="visaApplicationDetails && Object.keys(visaApplicationDetails).length > 0"
                header="Sponsor’s Details"
                header-bg-variant="#f4f4f4"
                style="background-color:#f2f2f2"
            >
                <b-row  class="card-title-border-bottom">
                    <b-col md="3">
                        <b-form-group label="First Name">
                            {{ visaApplicationDetails.sponsorFirstName }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Last Name">
                            {{ visaApplicationDetails.sponsorLastName }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Age">
                            {{ visaApplicationDetails.sponsorAge }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Address">
                            {{ visaApplicationDetails.sponsorAddress }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Annual Income">
                            {{ visaApplicationDetails.sponsorAnnualIncome }}
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Employment Details">
                            {{ visaApplicationDetails.sponsorEmploymentDetails }}
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </b-card>
        <!-- MODEL OF CREATE DOCUMENT -->
        <b-modal
            v-model="modalShow"
            :title="ModelTitle"
            ok-title="Save"
            centered
            cancel-title='Cancel'
            id="update_document"
			@hidden="clearModal()"
            @cancel.prevent='closeCreateDocModule' 
            @ok.prevent='saveDoc()'
            :no-close-on-backdrop="true"
        >
 
            <validation-observer
            ref="formValidator"          
            >      
            <div class="mb-1">  
                <!-- <div v-if="isLoading" class="loading loading-custom">  -->
                <!-- </div>      -->
                <div v-if="isLoading==true" class="loading loading-custom">         
                    <div class="effect-1 effects"></div>
                    <div class="effect-2 effects"></div>
                    <div class="effect-3 effects"></div>     
                </div>
                <!-- <b-spinner variant="primary" v-if="isLoading" large class="pageLoadSpinners"/> -->
                <b-row>  
                    <b-col md="6"> 
                        <b-form-group label="Document Name">
                        <validation-provider
                        #default="{errors}"
                        name="document Name"
                        rules="required"
                        >  
                        <b-form-input 
							v-model='docModule.docName' 
							name='docName' 
							:disabled='isLoading==true'
							placeholder='Enter Text'  
							:state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small> 
                        </validation-provider> 
                        </b-form-group>        
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Upload Document" class="modal_filetype" title="please select first filetype" >
                            <validation-provider
                            #default="{errors}"
                            name="Document files"
                            >
                              <b-form-file multiple :state="errors.length > 0 ? false:null"  @change="handleFileChange($event)" :disabled="docModule.docType==''" placeholder="Upload Document"  v-model='docModule.docFile' drop-placeholder="Drop file here..."/> 
                              <!-- <small class="text-danger" v-for="(file,index) in docModule.docFile" :key="index">{{ errors[0] }}</small> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                              <small class="text-danger" v-if="docModule.errorMessage.selectFirst.length > 0">{{ docModule.errorMessage.selectFirst }}</small>
                            </validation-provider>
                        </b-form-group>
                        <span v-if="!docModule.docFile.length" style="word-break: break-all;">
                            {{getNameWithoutId(selectedDocName)}}
                        </span>
                        <span v-for="(img,index) in docModule.docFile" :key="index">
                            {{img.name}}
                        </span>
                    </b-col>
                </b-row>
            </div>
            </validation-observer> 
        </b-modal>

         <!-- DELETE MODEL -->
        <b-modal
        id="delete-field"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedRemoveData=''"
        v-if="selectedRemoveData!=''"
        @ok="deleteField(selectedRemoveData)"
      >
      <div class="create_account_img">
      <img src="@/assets/images/erflog/DeletePopup.png" alt="">
    </div>
        <div style="text-align: center;" v-if="selectedRemoveData!=''">
            <label class="s_modal_title">Delete Field</label>
            <p>Are you sure you want to delete these document?</p>
        </div>
       </b-modal>
        <div v-if="isSpinner" class="loading loading-custom">         
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
       </div>

	   <b-modal id="choose-date-modal" title="Book date" :no-close-on-backdrop="true" @ok="(e)=>{e.preventDefault(); changeStatus({status:'Booked',variant:'info'})}" @hidden="visaDate=''">
			<validation-observer ref="dateValidator">
			<b-row>
				<b-col>
					<b-form-group label="Date">
						<validation-provider
							#default="{errors}"
							name="date"
							rules="required"
                        >  
							<input type="date" class="form-control" v-model="visaDate" :min="`${new Date().getFullYear()}-${addZero(new Date().getMonth()+1)}-${addZero(new Date().getDate())}`">
							<small class="text-danger">{{ errors[0] }}</small> 
						</validation-provider>
					</b-form-group>
				</b-col>
			</b-row>
			</validation-observer>
	   </b-modal>
    </div>    
</template>
<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
 import { required } from 'vee-validate/dist/rules'
import { BSpinner,BPagination,BFormSelect,BTable,BRow,BCol, BCard,
BFormInput,BDropdown,BDropdownItem,BButton,BImg, BFormFile, BTab,
 BTabs, BFormGroup, BFormRadio, BFormRadioGroup,BBadge} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//FIREBASE IMPORT
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import * as uploadFile from '@/utils/FirebaseQueries/storageQuery/storageQueries.js'
import { dbCollections,settingsCollectionDocs } from '@/utils/firebaseCollection.js';
import Ripple from 'vue-ripple-directive';
import moment from 'moment'

const db = firebase.firestore();

extend('required',required)

export default {
  components: {
    BPagination,
    BFormFile,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    BImg,   
    VSelect,
    ValidationProvider,
    ValidationObserver, 
    BTab,
    BTabs,
    BFormGroup,
    BFormRadio,
    BCard,
    BFormRadioGroup,
    BSpinner,
    BFormSelect,
    BBadge
  },
  directives: {
    Ripple,
  },
  props:["studentId","studentData"],
  data() {
    return {
      selectedDocName:"",
      items: [],
	  visaDate:"",
      searchQuery:'',
      newDocModule:[],
      arry:[
          "Student",
          "Admin",
          "Employee"
      ],
      category:'', 
      categoryArry:[],
      storageFolder: 'visa-documents',
      filesSelected:{},
      images:[],
      isLoading:false,
      newField:[],
      columns:[       
        // {
        //   label: 'No.',
        //   key: 'no', 
        // },
        {
          label: 'Sr no.',
          key: 'no',
        //   sortable:true
        },
        {
          label: 'Document Name',
          key: 'document_name.name',
          sortable:true
        },
        {
          label: 'File',
          key: 'upload_demo_file',
          sortable:true
        },
        {
          label: 'Created Date',
          key: 'createdAt',
          sortable: true
        },
        {
          label: 'Action',
          key: 'action', 
        },
      ],
      modalShow:false,
      docModule:{
          id:'',
          docName:'',
          docType:'single',
          docFile:[],
          errorMessage:{
              selectFirst:''
          }
      },
      ModelTitle:"Add Document",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],   
      selectedRemoveData:{},
      isSpinner:false,
      companyDocTable:[],
      totalPagination:0,
      selctedCourseObject:{},
      visaApplicationDetails:{},
      onlyRead:false,
    }
  },
//    watch: {
//     $route: function () {        
//         this.init();          
//     }
//    },
  created(){
    this.getApplicationData();
    let userAccess = JSON.parse(localStorage.getItem('userAccess'));
    if(userAccess) {
        let index = userAccess.findIndex((ele)=>{
            return ele.name === "Student List"
        })
        if(index !== -1) {
            if(userAccess[index].allow.toLowerCase() == 'read') {
                this.onlyRead = true;
            }
        }
    }
      this.init() 
      this.$root.$on('addVisaDocument',()=>{
        //   alert("DOON")
        this.ModelTitle = "Add Document";
        this.docModule={
          id:'',  
          docName:'',
          docType:'single',
		  studentId:"",
          docFile:[],
          errorMessage:{
              selectFirst:''
          }
        }  
        this.modalShow=true; 
        // this.$refs.openCreateDocModal.click();
        this.$root.$emit('stopSpinner')
      })

	  this.visaDate = this.studentData.visaDate || "";
  },
  methods:{
    handleRowClick() {
        if(this.$route.meta.resource == 'ACL') {
            this.$router.push({name:'studentapplicationDetails',params: { id: this.selctedCourseObject.appId,name:this.selctedCourseObject.applicationNo }})
        } else {
            this.$router.push({name:'application-mgmt-Info',params: { id: this.selctedCourseObject.appId,name:this.selctedCourseObject.selctedStudentName }})
        }
    },
    getApplicationData() {
        var self = this;
        try {
            db.collection(dbCollections.APPLICATIONS).where('selectedStudent.id','==',self.studentId).orderBy("createdAt",'desc').limit(1).get().then((rewss)=>{
                if(!rewss.empty) {
                    rewss.forEach((doc)=>{
                        if(doc.data().finalSelected && Object.keys(doc.data().finalSelected).length > 0) {
                            self.selctedCourseObject = {
                                applicationNo : doc.data().applicationNumber,
                                collegeName : doc.data().finalSelected.cllgName,
                                universityName : doc.data().finalSelected.uniName,
                                fieldName : doc.data().finalSelected.fieldName,
                                applicationFees : doc.data().finalSelected.applicationFee,
                                appId : doc.data().id,
                                selctedStudentName : doc.data().selectedStudent.name,
                                courseName : doc.data().finalSelected.courceName,
                            }
                        }
                    })
                } else {
                    self.selctedCourseObject = {}
                }
            }).catch((error) => {
                console.error(error);
            });    
        } catch (error) {
            console.error(error);
        }
    },
    changeRoute(){
        var self = this;
        let userData = JSON.parse(localStorage.getItem('currentUser'));
        if(userData.role == 'employee'){
            self.$router.push({name: 'visa-application-edit',params:{id:this.visaApplicationDetails.id}})
        } else {
            self.$router.push({name: 'visa-application',params:{id:this.visaApplicationDetails.id}})
        }
    },
    getNameWithoutId(imageName)
    {
        var self = this

        var ext = imageName.split('.')
            ext = ext[ext.length-1]
            var tmp = imageName.split('_')
            var name = ''
            // name = name.splice(name.length-1,1)

            tmp.forEach((vals,index)=>{
            if(index==0)
            {
                name+=vals.trim()
            }
            else if(index!=tmp.length-1)
            {
                name+='_'+vals.trim()
            }
            })
            name+='.'+ext

        return name;
    },
    addZero(val){
        return val > 10 ? val : "0"+val;
    },
	async changeStatus(status)
	{
		var self = this

		let valid = true;

		self.studentData.visaDate = self.visaDate;

		if(status.status === "Booked") {
			valid = await self.$refs.dateValidator.validate();
		}

		if(valid) {
			if (self.studentData.visaStatus === undefined) {
				self.studentData.visaStatus = {};
			}
			self.studentData.visaStatus = status;
	
			let obj = {
				updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
				visaStatus:status,
				visaDate: self.studentData.visaDate || ""
			}
	
			db
				.collection(dbCollections.USERS)
				.doc(self.studentId)
				.update(obj)
				.then(()=>{
					self.$root.$emit('showToastMessage','Status updated successfully.','success')
                    let notificationObj = {
                        title: `Visa`,
                        message: `Your Visa date is "${self.studentData.visaDate}"`,
                        type: 'visa',
                        userId: self.studentId,
                        currentUser:firebase.auth().currentUser.uid
                    }
                    if(status.status === "Booked") {
                        this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                        }).catch((error)=>{
                            console.error(error);
                        })
                    }
					self.$nextTick(()=>{
						self.$bvModal.hide('choose-date-modal');
					})
				})
				.catch(error=>{
					console.log(error)
					self.$root.$emit('showToastMessage',error.message,'danger')
				})
		}

	},
      init(){     
          var self = this;
          getQuery.getDataFromRootCollectionWithDocID(dbCollections.SETTING,settingsCollectionDocs.DOCUMENTCATEGORY,response=>{             
              self.categoryArry = [];
              if(response){
                  if(response.category !== undefined && response.category.length > 0){               
                      response.category.forEach(element => {
                          if(element){
                              self.categoryArry.push(element)
                          }
                      });
                  }
              }
          })    
        getQuery.getDataWithSpecificWhereCondition(dbCollections.VISAAPPLICATION,"studentId","==",self.studentId,res=>{ 
            if(res && res.length > 0){
                this.$root.$emit('isShowButtonCreateApplication',false)
                self.visaApplicationDetails = res[0];
            } else {
                this.$root.$emit('isShowButtonCreateApplication',true)
                self.visaApplicationDetails = {};
            }
        })      
        getQuery.getDataWithSpecificWhereCondition(dbCollections.VISADOCUMENTS,"studentId","==",self.studentId,res=>{ 
            if(res && res.length > 0){
                self.items = [];

                let itm = []
                res.forEach(values=>{
                    let allImgName = [];
                    let fileStr = '';
                    if(values && values.isDeleted == false){  
                        if(values.docFile.length > 0){
                                values.docFile.forEach(file=>{
                                    allImgName.push(file.imageName)
                                })
                        }   
                        fileStr = JSON.stringify(allImgName).replace('[','')
                        fileStr = fileStr.replace(']','').toString(); 
                        fileStr = fileStr.split('"').join('')  
                        fileStr = fileStr.split(',').join('/')  
                        itm.push({
                            document_name: {'name':values.documentName ? values.documentName : '',id:values.id ? values.id : ''},
                        //    File_Type:values.fileType,
                            upload_demo_file:values.docFile ? values.docFile : [],
                            collectionFile:values.docFile ? values.docFile : [],
                            createdAt: values.createdAt ? values.createdAt.seconds * 1000 : '',
                            action:''
                        })

                        self.totalPagination = itm.length;
                    }
                })
                self.items = itm.sort((a,b) => a.createdAt > b.createdAt ? 1 : -1)
            }
        })
      },
      checkDocBeforeUpdate() {
          if(this.docModule.docFile.length == 0) {
            this.docModule.errorMessage.selectFirst = ''
            this.docModule.errorMessage.selectFirst = `Please select one file`; 
            this.docModule.docFile = [];
        }
      },
      handleFileChange(evt) {
        this.docModule.errorMessage.selectFirst = ''
        let files = evt.target.files;
        var validImages = true
        if(files && files.length > 0){
            this.filesSelected = files;     
            if(this.docModule.docType !== '' && this.docModule.docType == 'double'){
                if(files.length !== 2){
                   this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                   this.docModule.docFile = [];
                   validImages = false
                   return;
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
            if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                if(files.length !== 1){
                   this.docModule.errorMessage.selectFirst = `Please select one file again`;
                   this.docModule.docFile = [];
                   validImages = false
                   return;
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            } 
            // EXTENSION VALIDATION
            files.forEach(val=>{
                if(val.type !== 'image/jpeg' && val.type !== 'image/jpg' && val.type !== ".jpeg" && val.type !== ".jpg" && val.type !== ".pdf" && val.type !== "application/pdf" && val.type !== ".png" && val.type !== "image/png" && val.type !== ".doc" && val.type == "application/doc"){
                   this.docModule.errorMessage.selectFirst = `File allow only jpeg ,jpg, png or pdf, doc[upload Again]`; 
                   this.docModule.docFile = [];
                   validImages = false
                }
            })
        }
      },
      addDocStorage(data,cbb){
        // let tempArry = []  
        if(data){
            let ext = data.name.split('.')
            ext = ext[ext.length-1];
            // this.studentData
            uploadFile.uploadImageFile(this.storageFolder,`${this.studentData.firstName}_${this.studentData.lastName}_${this.docModule.docName}.${ext}`,data,res=>{                    
            // tempArry.push(res)                    
            // if(storageRes.length == Number(newDocModule.docFile.length)){ 
                cbb(res)
            // }
            }) 
        }   
      },
      uploadDocumentFile(newDocModule,cb){
        let storageRes = []  
        var self = this;         
        if(newDocModule.docFile.length !== 0){ 
            let fileArry = newDocModule.docFile;
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= fileArry.length){  
                   cb(storageRes) 
                   return;                
               }
                self.addDocStorage(row,(saveRes)=>{                  
                    storageRes.push(saveRes)
                    count += 1;
                    uploadFileFunction(fileArry[count]);
                });              
            }
            uploadFileFunction(fileArry[count]);              
        }else{
            cb(storageRes)
        }
      },    
      setSubCategoryArry(objectData,index){
          var self = this;
          let subArry = [];  
          let subCategoryName = [];    
          self.categoryArry.forEach(ele=>{              
              if(ele.name == objectData){
                  subArry = ele.subCategory;                
              }
          })
          setTimeout(()=>{            
            if(subArry.length > 0){            
              subArry.forEach((values)=>{
                  if(values){   
                      subCategoryName.push(values.name)   
                  }
              })
             self.newDocModule[index].subCategory = subCategoryName;
            } 
          },1000)          
      },    
      removeDoc(Data){
        if(this.isLoading==false && Data.item.action !== true){
           this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText:'No',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                this.isLoading = true;
                 let removeData = this.items[Data.index];             
                let updateObj = {
                    isDeleted : true
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.VISADOCUMENTS,removeData.document_name.id,updateObj,res=>{              
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        text: 'Your file has been deleted.',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })
                    this.isLoading = false; 
                })        
                }
            })
        }        
      },
      removeDocImg(data,cb){
          var self = this;
          if(data.length > 0){
              if(data.length > 0){
                let count = 0;
                const deleteFileFunction = (row) => {
                if(count > data.length){  
                     cb(row) 
                    return                
                }
                    self.removeDocFile(row,(saveRes)=>{
                        count += 1;
                        deleteFileFunction(data[count]);
                    });
                }
                deleteFileFunction(data[count]);
              }
          }
      },
      removeDocFile(data,deleCb){
        var self = this;     
        if(data && data.imageName !== undefined){           
            uploadFile.removeImage(self.storageFolder,data.imageName,resp=>{  
                deleCb(resp)                
            })
        }else{
            deleCb(false)
        }
      },
      closeCreateDocModule(){
        if(this.isLoading){
            return;
        }
		this.modalShow = false
      },
	  clearModal(){
        if(this.isLoading){
            return;
        }
        this.ModelTitle="Add Document"
		this.docModule={
			id:'',
			docName:'',
			studentId:"",
			docType:'single',
			docFile:[],
			errorMessage:{
				selectFirst:''
			}
		};
	  },
      manageFiles(){
          var self = this;
          if(this.docModule.docFile.length > 0){
             let files = this.docModule.docFile;
            if(this.docModule.docType !== '' && this.docModule.docType == 'double'){
                if(files.length !== 2){
                   this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                   this.docModule.docFile = [];
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
            if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                if(files.length !== 1){
                   this.docModule.errorMessage.selectFirst = `Please select one file again`;
                   this.docModule.docFile = [];
                }else{
                    this.docModule.errorMessage.selectFirst = ''
                }
            }
          }
          //   EDIT DOCUMENT
          if(this.ModelTitle == "Update Document"){ 
            let removeDocFiles = [];
            let itemInd =self.items.findIndex(itemval=>{
                return itemval.document_name.id == this.docModule.id
            })
            if(itemInd > -1){
                removeDocFiles = self.items[itemInd].collectionFile;           
                if(this.docModule.docType !== '' && this.docModule.docType == 'double'){
                    if(removeDocFiles.length !== 2){
                        this.docModule.errorMessage.selectFirst = `Please select two file again`; 
                        this.docModule.docFile = [];
                    }else{
                        this.docModule.errorMessage.selectFirst = ''
                    }
                }
                if(this.docModule.docType !== '' && this.docModule.docType == 'single'){
                    if(removeDocFiles.length !== 1){
                        this.docModule.errorMessage.selectFirst = `Please select one file again`;
                        this.docModule.docFile = [];
                    }else{
                        this.docModule.errorMessage.selectFirst = ''
                    }
                }                      
            }     
          }
      },  
      checkDocBeforeEdit() {
        if(this.selectedDocName.length == 0) {
            if(this.docModule.docFile.length == 0) {
                this.docModule.errorMessage.selectFirst = ''
                this.docModule.errorMessage.selectFirst = `Please select one file`; 
                this.docModule.docFile = [];
            }
        }
      },
      saveDoc(){
          var self = this;
          if(self.isLoading) {
            return;
          }
          if(this.ModelTitle == "Add Document"){
            self.checkDocBeforeUpdate()
            if(self.$refs.formValidator){
                self.$refs.formValidator.validate().then(success => {
                    if (success && this.docModule.errorMessage.selectFirst == '' && self.studentId){                    
                        self.isLoading = true;                              
                        self.uploadDocumentFile(this.docModule,async (resp)=>{
                            if(resp){
								let docId = await db.collection(dbCollections.VISADOCUMENTS).doc().id;

                                let obj = {
									createdAt : firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
									updatedAt : firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
									isDeleted: false,                                    
									documentName: this.docModule.docName,
									fileType:this.docModule.docType,
									docFile: resp,
									id:docId,
									studentId:self.studentId
                                };

								db
									.collection(dbCollections.VISADOCUMENTS)
									.doc(docId)
									.set(obj)
									.then(()=>{
										self.isLoading = false;
                                        let notificationObj = {
                                            title: `Visa`,
                                            message: `A new Visa Document has been added`,
                                            type: 'visa',
                                            userId: self.studentId,
                                            currentUser:firebase.auth().currentUser.uid
                                        }
                                        this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                                        }).catch((error)=>{
                                            console.error(error);
                                        })
                                        self.$toast({
											component: ToastificationContent,
											position: 'top-right',
											props: {
												title: 'Document Add Successfully',
												icon: 'CoffeeIcon',
												variant: 'success',
											},
                                        })
                                        self.closeCreateDocModule()
									})
									.catch(error=>{
										self.isLoading = false;
										console.log(error);
									})
                            }
                        })
                    }
                })
            }
           }else{
            if(self.$refs.formValidator){
                self.$refs.formValidator.validate().then(success => {
                    self.checkDocBeforeEdit()
                    if(success && this.docModule.errorMessage.selectFirst == '' && self.studentId) {
                        this.updateDoc()
                    }
                })
            }
           } 
      },
      //   BIND DOCUMENT DATA
      editDoc(data){
        this.ModelTitle = "Update Document";
        this.docModule.docName=data.item.document_name.name;
        this.docModule.docType="single",
        this.selectedDocName = data.item.collectionFile.length ? data.item.collectionFile[0].imageName : '';
        this.docModule.docFile=[];
        this.docModule.id=data.item.document_name.id;
        this.docModule.errorMessage.selectFirst='';     
        this.docModule.studentId=this.studentId;
        this.modalShow=true;
        // this.$refs.openCreateDocModal.click();       
      },
      //   SAVE UPDATED FIELDS
     updateDoc(){
          var self = this;
          if(self.isLoading == true) {
            return
          }
           self.isLoading = true;   
           if(this.docModule.docFile.length > 0){  
            let removeDocFiles = [];
            let itemInd =self.items.findIndex(itemval=>{
                return itemval.document_name.id == this.docModule.id
            })
            if(itemInd > -1){
                removeDocFiles = self.items[itemInd].collectionFile;               
                self.removeDocImg(removeDocFiles,()=>{
                })                          
            }                 
           setTimeout(()=>{
               self.uploadDocumentFile(this.docModule,resp=>{
                    if(resp){
                        let obj = {                       
                            updatedAt : firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),                                                           
                            documentName: this.docModule.docName,
                            fileType:this.docModule.docType,
                            docFile: resp
                        }  
                        updateQuery.updateRootCollectionDataByDocId(dbCollections.VISADOCUMENTS,this.docModule.id,obj,res=>{                                                
                            self.isLoading = false;  
                            self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Document Update Successfully',
                                icon: 'CoffeeIcon',
                                variant: 'success',                               
                            },
                        })                                                       
                        self.closeCreateDocModule() 
                        })                           
                        // self.isLoading = false;
                    }
                }) 
            },1000) 
            }else{               
                let obj = {                       
                    updatedAt : firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),                                                           
                    documentName: this.docModule.docName,                                      
                }  
                updateQuery.updateRootCollectionDataByDocId(dbCollections.VISADOCUMENTS,this.docModule.id,obj,res=>{                                                                                            
                // self.isLoading = false;                       
                self.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Document Update Successfully',
                        icon: 'CoffeeIcon',
                        variant: 'success',                               
                    },
                })                                                       
                self.isLoading = false;
                self.closeCreateDocModule() 
                })   
                // self.isLoading = false;
           } 
     },
      downloadDocument(data,cb){
      let url = data.imageUrl;
      let name = this.getNameWithoutId(data.imageName);    
      this.$axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download',name);
            document.body.appendChild(fileLink);
            fileLink.click();
            cb(true) 
        })
        .catch(error=>{
             this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: error,
                        icon: 'CoffeeIcon',
                        variant: 'danger',                               
                    },
            })         
        })
      },
      downloadAttachments(data){
          if(data.item.upload_demo_file.length > 0){
            let count = 0;
            const uploadFileFunction = (row) => {
               if(count >= data.item.upload_demo_file.length){        
                   return           
               }
                this.downloadDocument(row,(saveRes)=>{
                    count += 1;
                    // if(data.item.upload_demo_file.length < count){
                        uploadFileFunction(data.item.upload_demo_file[count]);
                    // }
                });              
            }
            uploadFileFunction(data.item.upload_demo_file[count]);
            }
      },
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      if(this.filter !== ""){
        this.totalPagination = this.companyDocTable.length;                         
      }else{
        this.totalPagination = this.items.length;                         
      }
      },
      deleteField(data){
         if(this.isLoading==false && data.item.action !== true){   
            this.isLoading = true;
            let removeData = this.items[data.index];             
            let updateObj = {
                isDeleted : true
            }
            updateQuery.updateRootCollectionDataByDocId(dbCollections.VISADOCUMENTS,removeData.document_name.id,updateObj,res=>{                
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: 'Document Remove Successfully',
                  icon: 'CoffeeIcon',
                  variant: 'success',                               
              },
            }) 
            this.isLoading = false; 
            })        
         }
      },
    getDocDate(date){
        if(date){
            return moment(new Date(date)).format("DD MMM, YYYY");
        } else { 
            return "N/A";
        }
    },
  },
  computed:{
        getVisaDate(){
            if(this.studentData && this.studentData.visaDate){
                return moment(this.studentData.visaDate,"YYYY-MM-DD").format("DD MMM, YYYY");
            } else { 
                return "N/A";
            }
        },
        dataMeta(){
            const localItemsCount = this.companyDocTable ? this.companyDocTable.length : 0
            if(this.filter !== "" && this.filter !== null){          
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.companyDocTable.length,
            }
            }else{
                  return {
                    from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                    to: this.perPage * (this.currentPage - 1) + localItemsCount,
                    of: this.items.length,
                 }
            }          
      }
  }
}
</script>
<style lang="scss">
.per-page-selector {
  width: 170px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */
.s_modal_title{
    width: 100%;
    font-weight: 600;
    font-size: 20px;
}
.documentable_management_wrapper.position-relative.p-0_table.table-responsive {
    padding: 0px!important;
}
.modal_filetype .custom-file-label::after {
    content: "Upload Document" !important;
    text-transform: capitalize;
}
.addLinkToAnchor{
    text-decoration: underline !important;
    color: #0D4EA0 !important;
    font-weight: 600;
    font-size: 16px;
}
</style>
