<template>
	<div v-if="isLoading==true" class="loading loading-custom">         
		<div class="effect-1 effects"></div>
		<div class="effect-2 effects"></div>
		<div class="effect-3 effects"></div>     
	</div>
	<div v-else>
		<div v-if="docId && Object.keys(userData).length">
			<VisaDocuments :studentId="docId" :studentData="userData"/>
		</div>
	</div>
</template>

<script>
import VisaDocuments from '@/views/admin/studentManagement/visaDocuments.vue';
import firebase from '@/utils/firebaseInit';
import { dbCollections } from '@/utils/firebaseCollection';
const db = firebase.firestore();

export default {
	name:"visaDocumentsModule",
	components: {
		VisaDocuments
	},
	data(){
		return {
			docId:"",
			userData:{},
			isLoading:true,
		}
	},
	created() {
		var self = this;

		self.docId = JSON.parse(localStorage.getItem('userData')).id;

		console.log("ID",self.docId)

		db
			.collection(dbCollections.USERS)
			.doc(self.docId)
			.get()
			.then((doc)=>{
				if(doc.exists) {
					self.userData = doc.data();
					self.userData.visaStatus = doc.data().visaStatus || { status:"Pending", variant:"warning"};
                	self.userData.visaDate = doc.data().visaDate || "";
				}
				self.isLoading = false;
			}).catch(error=>{
				self.isLoading = false;
				console.log("error",error.message);
			})
	}
}
</script>

<style>

</style>